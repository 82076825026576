$white: #ffffff;
.white {
  background-color: $white; }

$green: #7ac843;
.green {
  background: $green; }

.background-white .green {
  margin-bottom: -3.25rem;
  margin-top: 3.25rem; }

$dark-green: darken($green, 15%);


$light-green: lighten($green, 42%);
.light-green {
  background-color: $light-green; }

$dark-grey: #303030;
.dark-grey {
  background-color: $dark-grey; }

$medium-grey: #3e3e3f;
.medium-grey {
  background-color: $medium-grey;
  color: white !important; }

$light-grey: lighten($medium-grey, 70%);
.light-grey {
  background-color: $light-grey; }

$success: #a4bb54;
.success {
  background-color: $success; }

$warning: #f08a24;
.warning {
  background-color: $warning; }

$error: #8c2e0b;
.error {
  background-color: $error; }
