.reverse {
  color: $white;
  p, a, h1, h2, h3, h4, h5, h6, li, td {
    color: $white; }

  a:hover {
      color: $dark-grey !important; }
  a.button {
     &:hover {
      color: $dark-green !important; } } }


.nav-container .reverse a:hover {
   color: $green !important; }
