#wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  &.show-menu {
    #canvas {
      transform: translateX(-300px); } } }

#canvas {
  background: #f2f2f2 none repeat scroll 0 0;
  height: 100%;
  position: relative;
  transform: translateX(0);
  transition: .3s ease all;
  width: 100%; }
