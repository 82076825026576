.donate,
.applicant,
.newsletter {
  height: auto;

  .row {
    margin-left: 0;
    display: block; }

  input[type=text],
  input[type=number] {
    border-radius: 0;
    padding: 0 0 0 10px; }

  textarea {
    border-radius: 0;
    padding: 5px 0 0 10px; }

  select {
    border-radius: 0;
    padding: 0 0 0 10px; }

  .prefix {
    &.complete {
      border-right: 1px #ccc solid; } } }

.donate-errors {
  color: #f10d28; }


.appointment {
  height: auto;

  .row {
    margin-left: 0;
    display: block; }

  .prefix {
    border-right: 1px #ccc solid; }

  input[type=text] {
    border-radius: 0;
    padding: 0 0 0 10px; }

  textarea {
    border-radius: 0;
    padding: 5px 0 0 10px; }

  select {
    border-radius: 0;
    padding: 0 0 0 10px; }

  .prefix {
    &.complete {
      border-right: 1px #ccc solid; } } }




.auction {
  height: auto;

  .row {
    margin-left: 0;
    display: block; }

  input[type=text] {
    border-radius: 0;
    padding: 0 0 0 10px; }

  textarea {
    border-radius: 0;
    padding: 5px 0 0 10px; }

  select {
    border-radius: 0;
    padding: 0 0 0 10px; }

  .prefix {
    word-wrap: break-word;
    &.complete {
      border-right: 1px #ccc solid; } }

  .field-hidden {
    display: none; }


  .js-total-field {
    border-radius: 0;
    padding: 5px 0 0 10px;
    background-color: #fff;
    border: 1px solid #c9c9c9;
    box-shadow: none;
    display: block;
    margin: 0 0 1.28571rem;
    width: 100%;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: box-shadow .45s ease 0s,border-color .45s ease-in-out 0s;
    cursor: pointer;
    min-height: 37px; } }

.auction-errors {
  color: #f10d28; }

.form-checkbox {
    display: flex;
    margin: 0 0 1.28571rem;
    width: 100%;

    input[type="checkox"] {
        border: 1px solid #c9c9c9;
        box-shadow: none;
        display: block;
        margin: 0 10px 0 0; } }
