#off-canvas-menu {
  right: -300px;
  height: 100%;
  padding: rem(20) 0px;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 99;
  ul {
    li {
      padding-bottom: 1rem;
      padding-top: 1rem;
      &:first-child {
        padding-bottom: 0;
        padding-top: 0; }
      p {
        margin-bottom: 0; }
      ul {} } } }




.mobile-child-menu {
  display: none; }


.mobile-child {
  width: 100%;
  list-style: none !important;
  margin-left: 0 !important;
  li {
    &:first-child {
      border-top: 1px solid #575759;
      margin-top: 5px; }
    &:last-child {
      border-bottom: 1px solid #575759 !important; }
    padding: 0 5px !important;
    z-index: 5;
    a {
      line-height: calculateRem(35px) !important; } } }
