.left-margin {
  margin-left: rem(18); }

.right-margin {
  margin-right: rem(18); }

.kill-padding {
  padding: 0px; }

.kill-margins {
  margin: 0px;
  font-size: 1.375rem; }
