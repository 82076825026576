// Buttons
@import '../base/colors';
@import '../base/variables';
@import '../base/utilities';
@mixin buttons {
    button,
    a.button,
    .button a,
    button[type=submit],
    .fr-class-highlighted,
    input[type=submit] {
      appearance: none;
      background: $green !important;
      border: 0 solid $white;
      border-radius: $default-radius !important;
      color: $white;
      cursor: pointer;
      display: inline-block;
      font-family: $primary-font;
      font-size: $default-font-size;
      font-weight: 700;
      font-style: normal;
      line-height: 50px;
      margin: 18px 0;
      padding: 0 24px;
      position: relative;
      text-align: center;
      text-decoration: none;
      top: 0;
      z-index: 3;
      transition: top 100ms ease-out 0s, background-color 200ms ease-out 0s;
      a {
        color: $white; }
      i {
        margin-right: 5px; }
      &.small {
        border-radius: 10px !important;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 30px;
        padding: 0 14px;
        &.anchor-link {
          border-radius: 5px; } }
      &.large {
        font-size: 16px;
        font-weight: normal; } }

    .green a.button.small {
        margin-left: 0.8rem; }

    a.inlineButton.button,
    .inlineButton.button {
      color: $white;
      margin: 10px;
      &:after {
        display: none; } }

    .fr-file.primary.button {
      background-color: $green !important;
      color: white !important;
      margin-top: 0;
      padding: 0.875rem 1.75rem 0.9375rem !important;
      &:hover {
        background: darken($green, 10%) !important; }
      &:after {
        display: none !important; } }

    .fr-view {
      .fr-file:after {
        display: none !important; } }

    button:hover,
    a.button:hover,
    .button a:hover,
    button[type=submit]:hover,
    input[type=submit]:hover {
      background: darken($green, 10%);
      text-decoration: none;
      top: 0px;
      a {
        color: $white; } }

    button:active,
    a.button:active,
    .button a:active,
    button[type=submit]:active,
    input[type=submit]:active {

      background: darken($green, 10%);
      text-decoration: none;
      top: 2px;
      a {
        color: $white; } }

    button:focus,
    a.button:focus,
    .button a:focus,
    button[type=submit]:focus,
    input[type=submit]:focus {
      background: darken($green, 10%);
      text-decoration: none;
      top: 2px;
      a {
        color: $white; } }

    button.secondary,
    a.button.secondary,
    .button.secondary a {
      background-color: $medium-grey;
      color: #ffffff;
      a {
        text-decoration: none; } }

    button.secondary:hover,
    a.button.secondary:hover,
    .button.secondary a:hover {
      background: darken($medium-grey, 10%);
      color: #ffffff;
      text-decoration: none; }

    button.secondary:active,
    a.button.secondary:active,
    .button.secondary a:active {
      background: darken($medium-grey, 10%);
      a {
        text-decoration: none; } }

    button.secondary:focus,
    a.button.secondary:focus,
    .button.secondary a:focus {
      background: darken($medium-grey, 10%);
      a {
        text-decoration: none; } }

    button.ghost,
    a.button.ghost,
    .button.ghost a {
      background-color: transparent;
      border: 1px solid $white;
      a {
        text-decoration: none; } }

    button.ghost:hover,
    a.button.ghost:hover,
    .button.ghost a:hover {
      background-color: $white;
      color: $medium-grey;
      a {
        color: $medium-grey;
        text-decoration: none; } }

    button.ghost:active,
    a.button.ghost:active,
    .button.ghost a:active {
      background-color: $white;
      color: $medium-grey;
      a {
        color: $medium-grey !important;
        text-decoration: none; } }

    button.ghost:focus,
    a.button.ghost:focus,
    .button.ghost a:focus {
      background-color: $white;
      color: $medium-grey !important;
      a {
        color: $medium-grey !important;
        text-decoration: none; } }

    button[type=submit] {
      background-color: $green;
      color: $white;
      font-size: 18px;
      font-weight: normal; }

    a.button.alert {
      background-color: #cc4b37; } }

