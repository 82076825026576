#admin-nav {
  display: inline-block;
  height: 60px;
  position: relative;
  z-index: 1000;
  nav {
    position: relative;
    .admin-menu {
      max-width: 1280px;
      width: 40%;
      float: left;
      li {
        display: table-cell;
        &.has-dropdown {
          position: relative;
          z-index: 7;
          &:after {
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border: inset 6px;
            content: '';
            border-bottom-width: 0;
            border-top-style: solid;
            border-color: #087300 transparent transparent;
            right: 12px;
            margin-top: -31px; }
          &:hover {
            background-color: #00A000;
            &:after {
              background-color: #00A000; } }

          i {
            position: relative;
            top: -4px; } }

        a {
          line-height: calculateRem(60px);
          font-size: calculateRem(17px);
          padding-right: 32px;
          padding-left: 5px; }



        ul {

          &.dropdown {
            display: none;
            margin-left: 0;
            position: absolute;
            top: 60px; }

          li {
            display: block;
            padding: 0px rem(15);
            text-align: left;
            border-bottom: 1px solid #087300;
            width: 100%;
            white-space: nowrap;
            &:hover {
              background-color: #00A000;
              &:after {
                background-color: #00A000; } }
            &:first-child {
              padding-top: rem(5); }
            &:last-child {
              border-bottom: none;
              padding-bottom: rem(5); }
            a {
              line-height: 32px; } } } } }

    .utility-menu {
      width: 100%;
      float: right;
      margin-top: rem(11);
      li {
        a {
          line-height: calculateRem(35px);
          font-size: calculateRem(17px); } } } } }


.dark-green {
  display: table-cell;
  background-color: #087300; }
