ul {
  &.inline, &.files, &.sections, &.section-files {
    list-style: none;
    margin-left: 0;
    li {
      list-style: none; } }
  &.inline {
    li {
      display: inline-block;
      list-style: inherit;
      margin-right: rem(20); } } }

hr {
  border-color: darken($light-grey, 15%);
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  margin: 2rem 0; }

pre code {
  background: lighten($medium-grey, 70%);
  border: 1px solid $medium-grey;
  display: block;
  height: auto;
  max-height: 200px;
  overflow: scroll;
  padding: rem(20);
  white-space: pre-line; }

//// form styles

label {
  font-size: rem(16);
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  color: $dark-grey;
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;

  &.error {
    color: $error; } }

input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="text"],
textarea,
select {
  background-color: $white;
  border: 1px solid lighten($dark-grey, 60%);
  border-radius: rem(5);
  padding: rem(5) rem(10);
  box-shadow: none;
  display: block;
  margin: 0px 0px rem(18);
  width: 100%;
  font-size: $default-font-size;
  appearance: none;
  transition: box-shadow 0.45s ease 0s, border-color 0.45s ease-in-out 0s;
  cursor: pointer;
  &.error {
    background-color: lighten($error, 65%);
    border: 1px solid $error; }

  &.error + label {
    color: $error; }

  &.success {
    background-color: lighten($success, 65%);
    border: 1px solid $success; }
  &.valid {
    color: $dark-grey; }
  &.auto-width {
    width: auto; } }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
select,
textarea {
  color: $medium-grey; }

select {
  background-image: url('../images/arrow-down.png');
  background-position: 90% 50%;
  background-repeat: no-repeat;
  padding: 5px;
  @include breakpoint('screen' $medium) {
    background-position: 96% 50%; }
  &.auto-width {
    background-position: 80% 50%; } }

input[type="checkbox"],
input[type="radio"] {
  display: none; }

input[type="checkbox"] + label span:first-child,
input[type="radio"] + label span:first-child {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: -1px 10px 0 -5px;
  vertical-align: middle;
  cursor: pointer; }

input[type="radio"] + label span:first-child {
  border-radius: 50%; }

input[type="checkbox"] + label span:first-child,
input[type="radio"] + label span:first-child {
  background: #d8d8d8;
  border: 1px solid $dark-grey; }

input[type="checkbox"]:disabled + label span:first-child,
input[type="radio"]:disabled + label span:first-child {
  border: none; }

input[type="checkbox"]:checked + label span:first-child:before {
  content: "\f00c";
  color: $dark-grey;
  font: normal normal normal 34px/1 FontAwesome;
  position: relative;
  top: -3px;
  left: 1px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

input[type="radio"]:checked + label span:first-child:before {
  content: "\f111";
  color: $dark-grey;
  font: normal normal normal 14px/1 FontAwesome;
  position: relative;
  top: 4px;
  left: 8px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

input[type="file"],
input[type="checkbox"] + label,
input[type="radio"] + label,
select {
  &.error {
    background: lighten($error, 65%); } } // lighten input background since error message is 100% red

select {
  appearance: none; }

select::-ms-expand {
  display: none; }

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus,
select:focus {
  background-color: $white !important;
  color: $dark-grey;
  border: 1px solid lighten($dark-grey, 60%);
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.25); }

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="month"]:disabled,
input[type="week"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="color"]:disabled,
textarea:disabled {
  border: 1px solid #dedede;
  color: #dedede; }


/* Adjust margin for form elements below */
input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: rem(18);
  vertical-align: baseline; }

textarea {
  resize: vertical; }

fieldset {
  border: 1px solid #DDDDDD;
  background: $white;
  overflow: hidden;
  padding: 1.25rem;
  line-height: 1.75;
  margin: 1.125rem 0;
  max-height: 10000px;
  @include transition(max-height 2s);

  legend {
    font-weight: bold;
    background: transparent;
    padding: 0 0.1875rem;
    margin: 0;
    margin-left: -0.1875rem;
    &.ui-element {
      cursor: pointer; } }

  fieldset {
    background: #F9F9F9;

    fieldset {
      background: $white; } }
  &.collapsed {
    max-height: 0px;
    overflow: hidden;
    @include transition(max-height 500ms); } }

span.error,small.error {
  display: block;
  padding: 0.42857rem 0.64286rem 0.64286rem;
  margin-top: -1px;
  margin-bottom: 1.14286rem;
  font-size: 0.85714rem;
  font-weight: normal;
  font-style: italic;
  background: $error;
  color: $white; }

label {
  small.error {
    display: inline-block;
    font-size: 0.85714rem;
    font-weight: normal;
    font-style: italic;
    background: none;
    color: $error; } }

.stats {
  float: right;
  width: 60%;
  max-width: 1280px;
  margin-top: 8px;
  @include breakpoint($break-mobile-all) {
    display: none !important; }
  @include breakpoint($break-medium) {
    display: none !important; } }


.statistics {
  width: 100%;
  margin: 0;
  text-align: center;
  list-style: none; }


.stat-box {
  color: white;
  float: left;
  background-color: #555555;
  width: 50px;
  min-height: 50px;
  padding: 16px 0 0 0;
  font-size: 16px;
  cursor: default;
  @include breakpoint((min-width $small + 1px)(max-width $tablet-medium)) {
    width: 40px;
    min-height: 40px;
    padding: 10px 0 0 0;
    font-size: 14px; }

  margin: 0 1px !important;
  font-weight: bold;
  list-style: inherit; }

.stat-box:hover {
    color: white; }

.stat-long {
  width: 250px;
  font-weight: normal;
  font-size: 15px; }

.tooltip {
  position: relative;
  display: inline-block; }

.tooltip {
  .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    margin-left: -60px; } }

.tooltip {
  .tooltip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; } }

.tooltip:hover {
  .tooltip-text {
    visibility: visible; } }


// errors use red text, red background not needed
label.error {
  background: none; }


// Hidden inputs

input.hidden {
  position: absolute;
  top: 0px;
  left: -99999px; }

i.submit-success {
  color: $success;
  font-size: rem(120); }

