#main-nav {
  background-image: url('/images/collar-tile.jpg');
  background-position: top;
  background-size: auto 80px;
  background-repeat: repeat-x;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.3);
  position: relative;
  z-index: 500;
  nav {
    @include container(1280px);
    width: 90%;
    position: relative;
    ul {
      margin: 0 auto;


      li {
        &.has-dropdown {
          position: relative;
          z-index: 3;
          i {
            font-size: rem(10);
            position: relative;
            top: -2px; } }
        a {
          display: block;
          line-height: rem(70);
          font-size: calculateRem(18px); }
        ul {
          &.dropdown {
            display: none;
            margin-left: 0;
            position: absolute;
            top: 80px;
            left: -16px;
            z-index: 1000; }
          li {
            background-color: $dark-grey;
            display: block;
            padding: 0px rem(15);
            text-align: left;
            border-bottom: 1px solid $medium-grey;
            width: 100%;
            white-space: nowrap;
            &:first-child {
              padding-top: rem(5); }
            &:last-child {
              border-bottom: none;
              padding-bottom: rem(5); }
            a {
              line-height: 32px; } } } } }
    #Donate-Tag {
      position: absolute;
      top: -15px;
      right: -50px;
      z-index: 100; } } }


#main-nav nav ul {
    max-width: 1280px; }
