article {
  @include container(1280px); }

aside {
  padding-top: rem(70);
  padding-bottom: rem(70); }


div {
  &.container {
    padding: rem(10); } }

header {
  &#site-header {
    padding-bottom: rem(20);
    padding-top: rem(20);
    position: relative;
    @include container(1280px);
    max-width: 1280px;
    width: 90%;
    i {
      position: absolute;
      right: 0px;
      top: 56px; } }
  ul#social {
    float: right;
    li {
      display: inline-block;
      margin: 0px rem(10);
      i {
        position: relative !important;
        top: 34px; } }
    @include breakpoint($break-medium) {
      margin-right: rem(80);
      top: 0px; }
    @include breakpoint($break-mobile-all) {
      display: none !important; } }

  .donate-header-button {
    line-height: 2.77143rem !important;
    @include breakpoint($large) {
      display: none !important; }
    @include breakpoint($medium) {
      margin-left: 2rem !important; }
    @include breakpoint($break-mobile-all) {
      margin-left: 1.25rem !important; } } }

nav {
  @include container(1280px);
  &.short {
    .button, ul {
      margin: 0; } }
  &.narrow {
    @include container(80%); } }

section {
  padding: 3.25rem 0 0 0;
  &.section {
    @include container(1280px);
    max-width: 1280px;
    width: 90%;
    &.sub {
      @include container(100%);
      margin: rem(20) 0px rem(20) rem(20);
      padding: 0px; } } }


.hero-container {
  background-color: $dark-grey;
  max-height: 400px;
  overflow: hidden;

  .hero-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
    height: 400px;
    @include breakpoint($break-mobile-all) {
      height: 150px; }
    @include breakpoint($break-medium) {
      height: 250px; }
    @include breakpoint($break-large) {
      height: 400px; }
    max-width: 1400px;
    margin: 0 auto; }

  h1 {
    position: relative;
    z-index: 10;
    color: $white;
    margin-top: rem(-100); } }



