.callout {
  position: relative;
  margin: 0 0 1rem;
  padding: 1rem;
  border: 1px solid hsla(0,0%,4%,.25);
  border-radius: 0;
  background-color: #fff;
  color: #0a0a0a;

  &.success {
    background-color: #e1faea;
    color: #0a0a0a; }

  &.alert {
    background-color: #f04124;
    color: #0a0a0a; }

  .close-button {
    background-color: #e1faea;
    right: 1rem;
    top: .5rem;
    font-size: 2em;
    line-height: 1;
    position: absolute;
    color: #8a8a8a;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius:  0; } }

