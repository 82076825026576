.section {

  .accordion {
    float: left;
    width: 100%;
    margin-top: -3.25rem;
    .grey {
      background-color: white;
      &:hover {
        background-color: $light-green !important; }
      .active {
        background-color: $light-green !important; }
      .content {
        background-color: $light-green !important; } }
    a {
      background-color: white !important;
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
      border-top: 1px solid #dcdcdc;
      padding: 1.5rem 2rem 1.25rem !important;
      color: $dark-green; }
    .accordion-navigation {
      a {
        &:hover {
         background-color: $light-green !important; } }
      table, ul, ol {
         a {
           border: 0px;
           background-color: inherit !important;
           padding: 0 !important;
           &:hover {
              background-color: inherit !important;
              color: $dark-grey; } } }
      a.button {
        background: $green !important;
        border: 0px solid $white;
        color: $white !important;
        padding: 0 1.71429rem !important;
        margin-right: 5px;
        &:hover {
          background: darken($green, 10%) !important; } }
      .content {
        float: left;
        width: 100%;
        margin-bottom: 25px;
        background-color: white;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        padding: .5rem 2.5rem !important;

        a.button.small {
          background: $green !important;
          padding: 0 1rem !important;
          &:hover {
            background: darken($green, 10%) !important; } }

        .many {
          list-style: none; }
        .card {
          margin-left: 0 !important;
          .button {
            background-color: $green !important;
            color: white !important;
            margin-top: rem(0);
            padding: 0.875rem 1.75rem 0.9375rem !important;
            &:hover {
              background: darken($green, 10%) !important; } } }
        a:not(.button) {
          border: 0px;
          background-color: inherit !important;
          padding: 0 !important;
          &:hover {
            background-color: inherit !important;
            color: $dark-grey; } } }


      .gray {
        background-color: white !important; } }
    .accordion-navigation.active {
        .accordion-header {
            &:before {
              content: "\f147";
              font-family: "fontawesome";
              font-size: rem(14);
              left: -8px;
              position: relative;
              top: -1px; } } }
    .accordion-header {
      text-align: left;
      font-size: calculateRem(22px);
      margin-bottom: 0;
      text-align: left;
      &:before {
        content: "\f196";
        font-family: "fontawesome";
        font-size: rem(14);
        left: -8px;
        position: relative;
        top: -1px; } }
    p {
      margin-bottom: 1rem; }
    ul, ol {
      margin-bottom: 1rem;
      li {
        border-bottom: 0px !important; } }
    ol ul, ul ol {
      margin-top: 0.5rem; } } }

.section .accordion li:last-child {
  border-bottom: 1px solid #dcdcdc; }

