.animal-block-container {
  h1 {
    @include breakpoint($break-mobile-all) {
      font-size: 2rem;
      text-align: center; } }
  .animal-block {
    float: left;
    width: 22%;
    margin: 15px;
    min-height: 500px;
    @include breakpoint($break-medium) {
      width: 45%; }
    @include breakpoint($break-mobile-all) {
      width: 100%;
      margin: auto 0;
      padding: 0 10%; }

    .animal-img-container {
      .animal-photo {
        width: 100%;
        padding: 20px;
        border-radius: 50%; }
      &:hover {
        cursor: pointer; } }

    .animal-block-name {
      text-align: center; }


    .animal-block-info {
      font-size: 16px;
      .animal-block-info-line {
        text-align: center;
        margin-bottom: 0; } } } }



.animal-show-container {
  float: left;
  width: 100%;
  @include breakpoint($break-mobile-all) {
    text-align: center;
    width: 100%;
    margin: auto 0;
    padding: 0 2%; }
  .animal-show {
    width: 100%;
    margin: auto 0;
    float: left;
    .animal-show-imgs {
      float: left;
      width: 40%;
      @include breakpoint($break-mobile-all) {
        width: 100%; }

      .owl-nav {
        background-color: #f2f2f2;
        margin-top: 0;
        .owl-prev {
          font-size: 80px;
          &:hover {
            font-size: 80px;
            background: none;
            color: black; } }
        .owl-next {
          font-size: 80px;
          &:hover {
            font-size: 80px;
            background: none;
            color: black; } } } }

    .animal-show-info {
      width: 60%;
      float: left;
      padding: 0 5%;
      @include breakpoint($break-mobile-all) {
        width: 100%;
        padding: 5% 0; }

      .animal-show-info-line {
        margin-bottom: 1%; } } } }





