// departing from normalize and reset stylesheets. this article makes sense...
// http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0 auto;
  font-size: 16px;
  background: #303030; }

body.admin {
  background: #f2f2f2 none repeat scroll 0 0; }

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: normal; }

a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px;
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

label {
  display: block; }

input {
  display: block; }

*:focus {
  outline: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
