.swatch {
  @include gallery(6 of 12);
  margin-bottom: rem(15);
  padding: rem(10);
  ul {
    margin-bottom: 0px;
    padding-top: rem(10);
    padding-bottom: rem(10);
    li {
      color: $white !important;
      label {
        color: $white !important;
        display: inline-block;
        font-size: rem(10);
        margin-bottom: 0px;
        margin-right: rem(5);
        small {
          color: $white !important; } } } }
  @include breakpoint('screen' $medium) {
    @include gallery(8 of 24); }
  @include breakpoint('screen' $large) {
    @include gallery(6 of 24); } }
