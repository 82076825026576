@media print {
    #off-canvas-menu,
    #admin-nav,
    .nav-container,
    #main-nav,
    .hero-container,
    .breadcrumbs,
    .section .accordion .accordion-header:before,
    .green,
    .button a,
    a.button,
    button,
    button[type=submit],
    input[type=submit],
    .footer {
        display: none !important; }


    ul.gallery li {
        margin-bottom: 0;
        padding: 0; }


    .accordion .accordion-navigation>.content,
    .accordion dd>.content {
        display: block; }


    .section .accordion a {
        background-color: #fff!important;
        border: 0px;
        padding: 0 !important; }



    .section .accordion .accordion-navigation .content {
        float: none;
        width: 100%;
        margin-bottom: 25px;
        border: 0px;
        padding: 0 !important; }

    .section .accordion li {
        border-bottom: 0px !important; } }



