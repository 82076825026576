/* Stacktable js defaults */
.stacktable {
    width: 100%; }

.st-head-row {
    padding-top: 1em; }

.st-head-row.st-head-row-main {
    font-size: 1.5em;
    padding-top: 0; }

.st-key {
    width: 49%;
    text-align: right;
    padding-right: 1%; }

.st-val {
    width: 49%;
    padding-left: 1%; }

/* RESPONSIVE EXAMPLE */

.stacktable.large-only {
    display: table; }

.stacktable.small-only {
    display: none; }

@media (max-width: 800px) {
  .stacktable.large-only {
    display: none; }

  .stacktable.small-only {
    display: table;

    /* CSS Only Responsive tables */ }
  .responsive-table {
    width: 100% !important;
    max-width: 100% !important;
    overflow: hidden !important;

    tr {
      max-width: 100% !important;

      th,
      td {
        width: 100% !important;
        max-width: 100% !important;
        float: left !important;

        a,
        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
          max-width: 100% !important;
          overflow: hidden !important;
          text-align: center !important; } } } } }
