.blog-news {
  img.mainimage {
    max-height: 400px;
    margin-bottom: rem(40);
    margin-left: rem(40);
    float: right;
    @include breakpoint($break-medium) {
      float: none;
      margin-left: 0px; }
    @include breakpoint($break-mobile-all) {
      float: none;
      margin-left: 0px; } } }

.blog-news-content {
  padding: 1rem 1rem 1.5rem;
  background: $white;
  display: inline-block;
  vertical-align: top;
  width: 46%;
  @include breakpoint($break-navigation) {
    width: 100%;
    display: block; }
  margin-right: 30px;
  margin-bottom: 30px;
  p {
    width: 90%; }
  h4 {
    margin-top: rem(10); }
  img {
    height: auto;
    margin-bottom: 20px; }
  .button {
    margin-right: 10px; } }


