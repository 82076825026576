.card {
  list-style: none;
  margin: rem(30) rem(0) rem(1) rem(0);
  text-align: center;

  h3 {
    font-size: rem(24);
    line-height: 1; }

  &.set {
    li {
      margin-bottom: rem(20);
      @include breakpoint('screen' $large) {
        @include gallery(6 of 12); } }
    &.many {
      li {
        @include breakpoint('screen' $medium) {
          @include gallery(6 of 12); }
        @include breakpoint('screen' $large) {
          @include gallery(4 of 12); } } } } }

.opportunity {
  border-radius: none;
  background: white;
  border: 1px solid darken($light-grey, 15%);

  h3 {
    margin-top: 5%; }

  .opportunity-heading {
    font-size: 1.28571rem;
    line-height: 1.57143rem;
    margin-top: rem(15);
    margin-bottom: rem(10); }

  a {
    margin-bottom: 2% !important; }
  .button {
      margin-left: rem(5);
      margin-right: rem(5); } }


.opportunity.light-green {
  background-color: $white !important; }
