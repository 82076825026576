.slider-container {
  width: 100%;
  height: 1000px;
  z-index: -1; }
.slider-item {
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  -webkit-box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.3);
  @include breakpoint($break-xlarge) {
    max-width: 1400px;
    width: 95%; }
  img {
    -webkit-filter: brightness(0.95);
    filter: brightness(0.95);
    background-size: cover; } }
.slide-caption {
  position: absolute;
  min-height: 80px;
  left: 12%;
  line-height: 1;
  top: 27%;
  width: 100%;
  z-index: 3;
  color: white;
  font-family: $primary-font;
  font-size: calculateRem(80px);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  @include breakpoint($break-medium) {
    font-size: calculateRem(44px);
    font-weight: bold;
    min-height: 40px;
    top: 32%; }
  @include breakpoint($break-mobile-all) {
    font-size: calculateRem(35px);
    font-weight: bold;
    min-height: 25px;
    top: 40%; }
  font-weight: normal;
  text-rendering: optimizelegibility; }
.slide-description {
  position: absolute;
  min-height: 40px;
  left: 12%;
  line-height: 1;
  top: 46%;
  width: 80%;
  z-index: 3;
  color: white;
  font-family: $primary-font;
  font-size: calculateRem(30px);
  font-weight: normal;
  text-rendering: optimizelegibility;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  @include breakpoint($break-medium) {
    font-size: 1.4rem;
    top: 45%; }
  @include breakpoint($break-mobile-all) {
    display: none; } }
.slide-button {
  position: absolute;
  cursor: pointer;
  left: 12%;
  line-height: rem(50);
  top: 60% !important;
  color: $white;
  font-weight: 700;
  margin: 0 !important;
  border-radius: $default-radius;
  border: 1px solid $white;
  background-color: transparent;
  z-index: 10;
  padding: 0px rem(24);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  a {
    z-index: 10;
    color: $white; }
  @include breakpoint($break-medium) {
    line-height: rem(40); }
  @include breakpoint($break-mobile-all) {
    line-height: 2rem;
    top: 25%; }
  &:hover {
    left: 12%;
    top: 54%;
    text-shadow: none;
    background-color: $white;
    color: $medium-grey;
    text-decoration: none;
    @include breakpoint($break-mobile-all) {
      top: 35%; } }
  &:active {
    left: 12%;
    top: 54%;
    @include breakpoint($break-mobile-all) {
      top: 35%; } } }


.owl-dots {
  bottom: 14px;
  @include breakpoint($break-mobile-all) {
    display: none !important; }
  position: absolute;
  left: 48%;
  .owl-dot {
    span {
      background: white !important; } }
  .active {
    span {
      margin-bottom: 14% !important;
      background: white !important;
      width: 14px !important;
      height: 14px !important; } } }

.owl-theme {
  background-color: #303030; }


