.front-heading {
  font-size: 2.750rem;
  color: $green;
  text-align: center;
  margin-bottom: 3rem;
  @include breakpoint($break-medium) {
    margin-bottom: 0; }
  @include breakpoint($break-mobile-all) {
    font-size: 1.950rem;
    margin-bottom: 0; } }

.background-white {
  background-color: white !important; }

.front-news {
  background-color: white;

  .news-container {
   @include breakpoint($break-large) {
      min-height: 880px; }
   @include breakpoint($break-navigation) {
      float: none;
      width: 100%;
      min-height: auto; } }


  .front-blog-section {
    border: 4px #f7f7f7 solid;
    background-color: #f7f7f7;
    float: left;
    @include breakpoint($break-xlarge) {
      min-height: 695px; }
    @include breakpoint($break-large) {
      min-height: 880px; }
    @include breakpoint($break-navigation) {
      margin-bottom: 3em;
      min-height: auto; }
    .front-blog-story {
      padding: 0 20px; }
    h4 {
      color: #7ac843;
      margin-top: rem(25);
      &:hover {
        color: $dark-grey; } }
    p {
      line-height: 1.3;
      margin-bottom: 0.5rem; }
    .button {
      margin: 20px; } } }



.insta-box {
  overflow: hidden !important;
  @include breakpoint($break-mobile-all) {
    max-width: 100%; } }

.instagram-media,
.instagram-media-rendered,
blockquote.instagram-media,
.instagram-media.instagram-media-rendered,
iframe#instagram-embed-0.instagram-media.instagram-media-rendered {
  max-width: 100% !important;
  padding: 0 !important;
  @include breakpoint($break-mobile-all) {
    width: 305px !important; } }
