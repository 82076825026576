// table styles

table {
  width: 100%;
  color: $dark-grey;
  font-family: $primary-font;
  font-size: rem(13);
  font-weight: normal;
  line-height: $default-line-height;
  margin: $default-spacing 0px;
  text-rendering: optimizeLegibility;

  thead {
    tr {
      th {
        color: $dark-grey;
        font-family: $primary-font;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase; } } }

  tbody {
    border-top: none;
    tr {
      border-bottom: solid 1px #eeeeee;
      th {
        color: $dark-grey;
        font-weight: normal;
        text-align: left;
        text-transform: uppercase; } } }
  p {
    margin: 0; } }

body.admin {
  table {
    thead {
      tr {
        th {
          background-color: $medium-grey;
          padding: rem(10) rem(15);
          a {
            color: $white;
            font-weight: bold;
            text-transform: uppercase; } } } }
    tbody {
      tr {
        &:nth-child(odd) {
          background: $white; }
        &:nth-child(even) {
          background-color: #eeeeee; }
        td {
          background: transparent;
          padding: rem(10) rem(15);
          a {
            margin: 0px; } } } } }

  .fr-box {
    table {
      thead {
        tr {
          th {
            background-color: transparent;
            color: $dark-grey;
            font-weight: normal;
            text-align: left;
            text-transform: uppercase;
            padding: 0px;
            a {
              color: $dark-grey;
              font-weight: normal;
              text-transform: uppercase; } } } }

      tbody {
        tr {
          &:nth-child(odd) {
            background: transparent; }
          &:nth-child(even) {
            background-color: transparent; }
          td {
            background: transparent;
            padding: 0px; } } } } } }
