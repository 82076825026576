body {
  font-size: 1em;
  line-height: 1.2em; }

h1, h2, h3, h4, h5, h6 {
  color: $medium-grey;
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 0.75rem;
  text-rendering: optimizelegibility;
  a {
    text-decoration: none;
    &:hover {
      color: $dark-grey; } } }

h1 strong,
h2 strong,
h3 strong,
h4 strong {
  font-weight: normal; }

h1 {
  font-size: rem(44);
  line-height: rem(48);
  margin-bottom: 2rem; }

h2 {
  font-size: rem(36);
  line-height: rem(40); }

h3 {
  font-size: rem(28);
  line-height: rem(32);
  margin-top: rem(30); }

h4 {
  font-size: rem(22);
  line-height: rem(26);
  margin-top: rem(30); }

h5 {
  font-size: rem(18);
  line-height: rem(22);
  font-weight: bold;
  margin-top: rem(30); }

h6 {
  font-size: rem(16);
  line-height: rem(19);
  font-weight: bold;
  margin-top: rem(30); }

.front-news h5 {
  font-weight: normal; }

a {
  color: $dark-green;
  line-height: inherit;
  &:hover,
  &:active {
    color: $dark-grey;
    text-decoration: none;
    & h2, h3, h4, h5 {
      color: $dark-grey; } }
  & h2, h3, h4, h5 {
    color: $dark-grey; } }


p {
  font-family: $primary-font;
  font-size: rem(14);
  line-height: rem(22);
  margin-bottom: 1.5rem;
  text-rendering: optimizeLegibility;
  a {
    em, i {
      font-family: $primary-font;
      font-style: italic; }
    strong, b {
      font-weight: bold; } } }


ul, ol {
  font-family: $primary-font;
  font-size: rem(13);
  font-weight: normal;
  line-height: $default-line-height;
  margin-left: rem(30);
  margin-bottom: 1.5rem;
  list-style: outside; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ul ul {
  margin-bottom: 0; }

ul ul:nth-child(even) {
  list-style-type: disc; }

ul ul:nth-child(odd) {
  list-style-type: disc; }

li {
  color: $dark-grey;
  font-size: rem(14);
  line-height: rem(18);
  margin-bottom: rem(5); }


.fr-view li a {
      font-weight: bold !important; }

.no-bullet {
  list-style: outside none none !important; }

ul.no-bullet {
  margin-left: 0; }

ul, ol {
  &.col {
    @include columns(2);
    @include breakpoint('screen' $large) {
      @include columns(3); } }
  &.many-col {
    @include columns(2);
    @include breakpoint('screen' $medium) {
      @include columns(3); }
    @include breakpoint('screen' $large) {
      @include columns(4); } } }

ul {
  &.col, &.many-col {
    margin: rem(60) 0px; } }

ul.contact {
  display: block;
  width: 100%;
  li {
    float: left;
    margin-right: rem(40);
    .map {
      width: 60%; } } }

ul.gallery {
  li {
    padding: rem(10) rem(20) rem(10) rem(0);
    margin-bottom: rem(40);
    @include breakpoint('screen' $medium) {
      @include gallery(12 of 24); }
    @include breakpoint('screen' $large) {
      @include gallery(8 of 24); }
    @include breakpoint($break-mobile-all) {
      padding: rem(0);
      margin-bottom: rem(50); } } }

.caption p {
  font-size: rem(12);
  font-weight: 600;
  color: lighten($dark-grey, 40%);
  line-height: 1.2;
  padding-top: 1.25em;
  padding-bottom: 0px; }

em,
i {
  font-family: $primary-font;
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold; }

small {
  font-size: 85%;
  line-height: 85%; }

sup {
  font-size: 50%; }

blockquote,
blockquote p {
  color: $medium-grey;
  font-family: $primary-font;
  font-size: rem(18);
  font-style: italic;
  line-height: rem(22);
  text-rendering: optimizeLegibility;
  margin-bottom: 2rem; }

label {
  color: $dark-grey;
  font-size: rem(13); }

.clearblock {
  clear: both; }
