.adopt-table {
  min-height: 500px; }

.list-body {
  width: 100%;
  color: inherit;
  background: #FFFFFF;
  border: solid 1px #DDDDDD; }
.list-body table {
  margin: 0; }

.list-table tr:nth-child(odd) {
  background: #F9F9F9; }

.list-item {
  padding: 10px 0;
  text-align: center;
  width: 33%; }


.list-item a {
  color: #00bcd4; }


#DefaultLayoutDiv {
  table {
    display: table;
    text-align: left; }
  td {
    display: table-row-group; }
  tbody {
    display: table-row-group; } }

.detail-label {
  text-align: left; }
.detail-value {
  font-weight: normal; }
