.back-to-top {
  position: fixed;
  bottom: rem(100);
  right: 0px;
  text-decoration: none;
  color: $white;
  background-color: $medium-grey;
  border: 1px solid lighten($medium-grey, 20%);
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 1.8em;
  padding: 0.5em;
  display: none;
  &:hover {
    background-color: $green;
    border: 1px solid $green;
    border-right: none;
    i {
      color: $white; } } }
