.main {
  &.left-col {
    @include breakpoint('screen' $medium) {
      @include span(16); } }
  &.right-col {
    @include breakpoint('screen' $medium) {
      @include span(16 at 9); } } }

.left-col {
  @include breakpoint($break-medium) {
    @include span(18); }
  @include breakpoint($break-large) {
    @include span(10); } }


.right-col {
  @include breakpoint('screen' $medium) {
    @include span(8 at 17); } }

