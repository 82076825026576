#main-nav {
  nav {
    a {
      color: $white;
      line-height: rem(70);
      font-weight: 300; } } }

.nav-container {
  width: 100%; }



.breadcrumbs-nav {
  ul {
    li {
      font-size: rem(11); } } }
