header {
  &.style-guide {
    p {
      font-size: rem(28);
      text-transform: uppercase; }
    &.secondary {
      p {
        border-top: 1px solid #e0e0e0;
        font-size: rem(18);
        padding-top: rem(10);
        text-transform: none; } } }
  &#site-header {
    i {
      cursor: pointer;
      font-size: rem(18); } } }

main {
  padding-bottom: 3.25rem; }

footer {
    .footer {
        @include container(1280px);
        max-width: 1280px;
        width: 90%;
        padding-bottom: rem(25);
        padding-top: rem(60);
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 1024px) {
            flex-direction: column; }

        @include breakpoint((max-width $tablet-medium)) {
            padding-bottom: rem(0); }


        img {
            vertical-align: top; }

        div.left-footer {
            padding-right: 25px;
            flex-basis: 20%;
            @media screen and (max-width: 1024px) {
                flex-basis: 100%;
                align-items: center;
                display: flex;
                flex-direction: column; }

            h4 {
                color: #7ac843;
                font-size: 1.4em;
                font-weight: bold;
                text-transform: uppercase; }
            ul {
                display: block;
                li {
                    color: #f5f5f5;
                    display: block;
                    margin-bottom: 20px;
                    @media screen and (max-width: 1024px) {
                        text-align: center; }
                    strong {
                        color: #7ac843;
                        font-weight: bold;
                        a {
                            color: #7ac843;
                            &:hover {
                                color: #7ac843; } } } } } }


        div.right-footer {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 80%;
            @media screen and (max-width: 1024px) {
                flex-basis: 100%; }

            h3 {
                color: #7ac843;
                font-size: 1.75em;
                font-weight: bold;
                margin: 0;
                text-transform: uppercase; }

            .logos {
                @media screen and (max-width: 1024px) {
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center; }
                img {
                    display: inline-block;
                    padding: 10px;
                    max-width: 200px;
                    margin-right: 2rem;
                    @media screen and (max-width: 675px) {
                        margin-right: 0; } } }

            hr {
                color: #f5f5f5;
                width: 100%;
                display: block;
                margin: 0;
                @media screen and (max-width: 1024px) {
                    display: none; } }

            ul#links {
                margin-top: 1.5rem;
                li {
                    color: #7ac843;
                    display: inline-block;
                    &.separator {
                        padding: 0 7px; }
                    a {
                        color: #7ac843; }

                    @media screen and (max-width: 675px) {
                        display: block;
                        text-align: center;
                        &.separator {
                            display: none; } } } } } } }

img {
  &#branding {
    width: 200px;
    @include breakpoint($break-large) {
      width: 175px; } }
  &#eisforeveryone {
    width: 150px;
    margin-left: 2rem;
    margin-right: 0 !important;
    @include breakpoint($break-xlarge) {
      width: 150px;
      margin-left: 2rem; }
    @include breakpoint($break-navigation) {
      margin: 1rem 1rem 3rem; } }
  &#sheltercount {
    width: 95px;
    margin-left: 3rem;
    margin-right: 0 !important;
    @include breakpoint($break-xlarge) {
      width: 95px;
      margin-left: 3rem; }
    @include breakpoint($break-navigation) {
      margin: 1rem 1rem 3rem; } } }


.fr-view {
    img.fr-dii.fr-fil {
        margin: 5px 40px 30px 0 !important; }
    img.fr-dii.fr-fir {
        margin: 5px 0 30px 40px !important; }
    img.fr-dib.fr-fil {
        margin: 0 0 30px 0 !important; }
    img.fr-dii {
        margin: 20px 0 !important; }

    p a {
      font-weight: bold; } }

nav {
  &.short {
    a {
      line-height: rem(36); } }
  a {
    &:hover {
      font-weight: 500;
      color: #bee4a5 !important; } } }

#admin-nav {
    nav {
        a {
          &:hover {
            color: $dark-grey !important; } } } }

section {
  &.separator {
    border-bottom: 1px solid $medium-grey; }
  &.narrow {
    padding: rem(5) 0px; }
  &.medium {
    padding: rem(30) 0px; } }


ul {
  &.tight {
    li {
      margin-right: rem(6); } } }

.breadcrumbs, .breadcrumbs-nav {
  color: $dark-grey;
  line-height: inherit;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  margin-bottom: 1rem;
  font-family: $primary-font;
  font-weight: normal;
  line-height: 1.28571rem;
  a {
    list-style: none;
    color: $dark-green;
    &:hover {
      color: $dark-grey; } } }

.breadcrumbs, .breadcrumbs-nav {
  a, small {
    font-size: rem(11); }
  .fa {
    color: darken($light-grey, 35%); } }
